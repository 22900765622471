<template>
  <ListagemUsers />
</template>

<script>
import ListagemUsers from "@/components/users/ListagemUsers.vue";

export default {
  components: {
    ListagemUsers
  }
};
</script>
